export const DashboardActionTypes = {
  DASHBOARD_LIST_REQUEST: 'DASHBOARD_LIST_REQUEST',
  DASHBOARD_LIST_SUCCESS: 'DASHBOARD_LIST_SUCCESS',
  DASHBOARD_LIST_FAIL: 'DASHBOARD_LIST_FAIL',

  SET_COMPLETED_DASHBOARD_CHALLENGES: 'SET_COMPLETED_DASHBOARD_CHALLENGES',
  SET_INPROGRESS_DASHBOARD_CHALLENGES:'SET_INPROGRESS_DASHBOARD_CHALLENGES',
  SET_DASHBOARD_ALL_CHALLENGE_ID: 'SET_DASHBOARD_ALL_CHALLENGE_ID',


  SET_COMPLETED_DASHBOARD_PROJECTS: 'SET_COMPLETED_DASHBOARD_PROJECTS',
  SET_INPROGRESS_DASHBOARD_PROJECTS: 'SET_INPROGRESS_DASHBOARD_PROJECTS',
  SET_DASHBOARD_ALL_PROJECT_ID: 'SET_DASHBOARD_ALL_PROJECT_ID',

  UPDATE_PROJECT_STATUS: 'UPDATE_PROJECT_STATUS',
  UPDATE_CHALLENGE_STATUS: 'UPDATE_CHALLENGE_STATUS',

  START_PROJECT: 'START_PROJECT',
  START_CHALLENGE: 'START_CHALLENGE',

  SUBMIT_PROJECT: 'SUBMIT_PROJECT',
  SUBMIT_CHALLENGE: 'SUBMIT_CHALLENGE',

  DELETE_PROJECT: 'DELETE_PROJECT',
  DELETE_CHALLENGE: 'DELETE_CHALLENGE',

  REMOVE_DASHBOARD_DATA: 'REMOVE_DASHBOARD_DATA',
}