import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ user, login, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user !== null && login === true) return <Component {...props} />;
        else return <Redirect to="/" />;
      }}
    />
  );
};

export default ProtectedRoute;
