import React, { useState } from 'react';
import ReactDom from 'react-dom';
import styled from '@emotion/styled';

const CardModalWrapper = styled.div`
overflow-Y:auto;
position:fixed;
top:0;
bottom:0;
left:0;
right:0;
z-index:10;
background-color:rgba(0,0,0,0.2);
`








export default function ModalComponent({children}) {

    
   
        return ReactDom.createPortal (

            <>
            <CardModalWrapper>
                
                {children}
               
            </CardModalWrapper>
            </>,
      document.getElementById('portal-root')
          )

   
  
}
