import styled from "@emotion/styled";

import React from 'react'
import {breakpoints,device} from '../common/breakpoints'


const ImageModalContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top:50px;
`;

const ImageModal = styled.img( props => ({
    width: 'auto',
  height: props.height?props.height:'150px',
 
  objectFit:'cover',
  borderRadius:props.borderRadius?props.borderRadius:'50%',
  }))

const TextDivModal = styled.div( props => ({
    display:'flex',
    flexWrap:'wrap',
    alignContent:'center',
   
   
flexDirection:'column',
textAlign:props.textAlign?props.textAlign:'center',
padding:props.padding?props.padding:'10px'
}))

const TextModal = styled.p(props => ({

    fontSize:props.fontSize?props.fontSize:'1rem',
    fontStyle:props.fontStyle?props.fontStyle:'italic',
    fontWeight:props.fontWeight?props.fontWeight:'600',
    color:props.color?props.color:'black',
   }))

const ButtonDiv = styled.div( props => ({
    display:'flex',
    marginTop:props.marginTop?props.marginTop:'1px',
    padding:props.padding?props.padding:'2px',
flexDirection:'row',
justifyContent:props.justifyContent?props.justifyContent:'center',
alignItems:props.alignItems?props.alignItems:'center',
}))

const CardModal = styled.div(props => ({
  padding:props.padding?props.padding:'10px',
  display:'flex',
  flexDirection:props.direction ? props.direction:'column',
  width:props.width ? props.width :'520px' ,
  position:'absolute',
  top:props.top?props.top:'50%',
  left:props.left ? props.left:'50%',
  borderRadius:props.borderRadius?props.borderRadius:'5px',
  height:props.height?props.height:'370px',
  backgroundColor:props.backgroundColor?props.backgroundColor:'white',
  zIndex:'11',
  transform:`translate(-${props.transformX?props.transformX:'50%'},-${props.transformY?props.transformY:'50%'})`,

 

  
}))

const PrimaryModalButton = styled.button(props => ({
  display:'flex',
  width:props.width ? props.width :'100px' ,
  height:props.height?props.height:'24px',
  background:props.background?props.background:'#3a2dce',
  border:props.border?props.border:'none',
  color:props.color?props.color:'white',
  fontStyle:props.fontStyle?props.fontStyle:'italic',
  fontWeight:props.fontWeight?props.fontWeight:'600',
  justifyContent:props.justifyContent?props.justifyContent:'center',
  alignItems:props.alignItems?props.alignItems:'center',
  marginTop:props.marginTop?props.marginTop:'2px',
  borderRadius:props.borderRadius?props.borderRadius:'5px',
  textDecoration:props.textDecoration?props.textDecoration:'none',
  marginRight:props.marginRight?props.marginRight:'0',
  cursor:'pointer'

}))




export const ModalStartandSubmitProjectChild = ({closeModal,setLoading1,heading,buttonText,description,ModalImage,headingUp}) => {
  return (
    <>
    <CardModal borderRadius='11px'>
    <TextDivModal padding='0px'>
      <TextModal fontStyle='normal' fontSize={device.sm?'16px':'18px'} fontWeight='700' >
       {headingUp}
      </TextModal>
      
    </TextDivModal>
    <ImageModalContainer>
<ImageModal width='230px' height='170px'  src={ModalImage}/>
    </ImageModalContainer>
    <TextDivModal>
      <TextModal fontStyle='normal' fontSize={device.sm?'16px':'18px'} fontWeight='700' >
       {heading}
      </TextModal>
      <TextModal color="#747474" fontStyle='normal' fontSize='14px' fontWeight='400' >
{description}      
</TextModal>
    </TextDivModal>
    <ButtonDiv> <PrimaryModalButton onClick={()=>{ 
       setLoading1(false)
      closeModal()}
     
     
    } fontWeight='700' fontSize='14px' fontStyle='normal' background='linear-gradient(152.32deg, #631DA8 0%, #8C44D5 98.04%)' height='40px' width='140px' borderRadius='30px'>{buttonText}</PrimaryModalButton></ButtonDiv>
   
    </CardModal>
    </>
  )
}
