const breakpoints ={
    xs:'0px',
    sm:'600px',
    md:'900px',
    lg:'1200px',
    xl:'1536px'
}

export const device = {
    xs:`(min-width: ${breakpoints.xs})`,
    sm:`(min-width: ${breakpoints.xs})`,
    md:`(min-width: ${breakpoints.xs})`,
    lg:`(min-width: ${breakpoints.xs})`,
    xl:`(min-width: ${breakpoints.xs})`,

}