import React from 'react';
import styled from '@emotion/styled';

const Banner = () => {
  return (
    <>
      <BannerWrapper>
        <BannerContainer>
          <p className="title">Welcome to Unbound 🎉 </p>
          <p className="description">
            You are now one step closer to becoming a Successful Developer
          </p>
        </BannerContainer>
      </BannerWrapper>
    </>
  );
};

export default Banner;

const BannerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 150px;
  border-radius: 10px;
  background: linear-gradient(90.28deg, #4d20ad -16.47%, #b8cbfb 153.83%);
  margin-top: 30px;
  justify-content: center;
  align-items: center;

  @media (max-width: 496px) {
    height: auto;
  }
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 2rem;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 10px;
    ${'' /* text-align: center; */}
  }

  .description {
    color: #ffffff;
  }

  @media (max-width: 496px) {
    margin: 40px 20px 30px 20px;
  }
`;
