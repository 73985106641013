export const ProfileActionTypes = {
  UPDATE_EDUCATION: 'UPDATE_EDUCATION',
  DELETE_EDUCATION: 'DELETE_EDUCATION',
  SET_EDUCATION_DETAILS: 'SET_EDUCATION_DETAILS',

  UPDATE_EXPERIENCE: 'UPDATE_EXPERIENCE',
  DELETE_EXPERIENCE: 'DELETE_EXPERIENCE',
  SET_EXPERIENCE_DETAILS: 'SET_EXPERIENCE_DETAILS',

  UPDATE_PROJECT: 'UPDATE_PROJECT',
  DELETE_PROJECT: 'DELETE_PROJECT',
  SET_PROJECT_DETAILS: 'SET_PROJECT_DETAILS',
};
