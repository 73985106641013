import { useState, forwardRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import { firestore } from "../../firebase/firebase";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  width: "80%",
  '@media (min-width: 780px)' : {
    width: '500px'
  }

};

const heading = {
  fontSize: "1.5rem",
  fontWeight: "bold",
  textAlign: "center",
};

const inputField = {
  width: "100%",
  marginBottom: "1rem",
  marginTop: "1rem",
};

export default function BasicModal({ open, setOpen, handleOpen, handleClose }) {
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [category, setCategory] = useState("");

  const handleSnackbarOpen = () => {
    setOpenSnackbar(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const submitHandler = async () => {
    if (name === "" || email === "" || message === "" || category === "") {
      setSnackbarMessage("Please fill all the fields");
      setSnackbarSeverity("warning");
      handleSnackbarOpen();
    } else if (!validateEmail(email)) {
      setSnackbarMessage("Please enter a valid email");
      setSnackbarSeverity("warning");
      handleSnackbarOpen();
    } else {
      try {
        await firestore
          .collection("feedback")
          .add({
            name,
            email,
            message,
            category,
          })
          .then(() => {
            setSnackbarMessage("Feedback submitted successfully");
            setSnackbarSeverity("success");
            handleSnackbarOpen();
            setName("");
            setEmail("");
            setMessage("");
            setCategory("");
            handleClose();
          });
      } catch (error) {
        setSnackbarMessage("Something went wrong");
        setSnackbarSeverity("error");
        handleSnackbarOpen();
      }
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseButtonContainer>
            <CloseIcon onClick={handleClose} />
          </CloseButtonContainer>
          <Typography
            id="modal-modal-title"
            sx={heading}
            variant="h6"
            component="h2"
          >
            Feedback
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Have been using Unbound? Please let us know how we can further
            improve
          </Typography>
          <FeedbackInput>
            <TextField
              sx={inputField}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              sx={inputField}
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
                onChange={(e) => setCategory(e.target.value)}
              >
                <MenuItem value="bug">Report a bug</MenuItem>
                <MenuItem value="feature">Request a feature</MenuItem>
                <MenuItem value="others">Others</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="outlined-multiline-flexible"
              label="Feedback"
              sx={inputField}
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </FeedbackInput>
          <ButtonContainer>
            <Button onClick={submitHandler}>Submit Feedback &rarr;</Button>
          </ButtonContainer>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

const FeedbackInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const Button = styled.button`
  display: flex;
  width: 200px;
  height: 40px;
  border: none;
  background: linear-gradient(90deg, #3a2dce 15.43%, #3a2dce 50%);
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-top: 10%;
  text-decoration: none;
  cursor: pointer;
  font-size: 17px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  &:hover {
    cursor: pointer;
  }
`;
